<template>
  <payment-receipt
    ref="PaymentReceipt"
    :hideRawData="false"
    :paymentID="paymentID"
    :patientID="patientID"
    :clinicID="clinicID"
  />
</template>

<script>
import PaymentReceipt from "@/components/commonComponents/Payment/PaymentReceipt.vue";

export default {
  components: {
    PaymentReceipt,
  },
  data() {
    return {
      paymentID: parseInt(this.$route.query.paymentID),
      patientID: parseInt(this.$route.query.patientID),
      clinicID: parseInt(this.$store.getters.userData.clinicID),
    };
  },
  mounted() {
    this.print();
  },
  methods: {
    print() {
      this.$refs.PaymentReceipt.print();
    },
  },
};
</script>