<template>
  <Container class="d-print-block" :class="{ 'd-none': hideRawData }">
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <ClinicDetail flexDirection="column" style="width: 50%">
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'PK: ' : 'Clinic: '"></b>
          {{ clinic.clinicName }}
        </span>
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'ĐC: ' : 'Addr: '"></b>
          {{ clinic.address }}
        </span>
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'ĐT: ' : 'Phone: '"></b>
          {{ clinic.hotline }}
        </span>
      </ClinicDetail>
      <ImageLogoBox>
        <img
          class="imageLogoBox_Image"
          src="../../../assets/CT_logo.png"
          alt="Logo Cao thang"
        />
      </ImageLogoBox>
    </div>
    <TitlePrescription>
      <span v-html="isVnLanguage ? 'Phiếu thu' : 'PAYMENT RECEIPT'"></span>
    </TitlePrescription>
    <PatientInfo class="mt-1">
      <div class="d-flex justify-space-between w-100 pl-3 pr-3">
        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Họ tên: " : "Name: " }}
            <span class="upper__case">{{ personalInfo.fullName }}</span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Giới tính: " : "Gender: " }}
            <span class="bold__case format-size">
              {{ personalInfo.genderTypeName }}
            </span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Địa chỉ: " : "Address: " }}
            <span>{{ personalInfo.address }}</span>
          </label>
        </div>

        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Mã hồ sơ: " : "Patient ID: " }}
            <span class="bold__case">{{ personalInfo.patientID }}</span>
          </label>
          <span class="patientInfo__item">
            {{ isVnLanguage ? "Ngày sinh: " : "BirthDay: " }}
            {{ formatDate(personalInfo.birthDate) }}
            <b class="bold__case">
              ({{ isVnLanguage ? "Tuổi: " : "Ages: " }}
              {{
                new Date().getFullYear() -
                new Date(personalInfo.birthDate).getFullYear()
              }})
            </b>
          </span>
        </div>
      </div>
    </PatientInfo>
    <DetailPrescription class="mt-2">
      <TableContent>
        <tbody>
          <tr>
            <th class="text-center">#</th>
            <th class="text-center">
              {{ isVnLanguage ? "Dịch vụ" : "Service " }}
            </th>
            <th class="text-center">{{ isVnLanguage ? "Số lượng" : "Qty" }}</th>
            <th class="text-center">{{ isVnLanguage ? "Giá" : "Price" }}</th>
            <th class="text-center">{{ isVnLanguage ? "Tổng" : "Total" }}</th>
          </tr>
          <tr v-for="item in payment.items" :key="item.order">
            <td class="content__prescription">
              <span>{{ item.order }}</span>
            </td>
            <td
              class="content__prescription"
              style="text-align: left; padding-left: 5px"
            >
              <span>{{ item.itemName }}</span>
            </td>
            <td class="content__prescription">
              <span>{{ item.quantity }}</span>
            </td>
            <td class="content__prescription">
              <span>{{ item.priceString }}</span>
            </td>
            <td class="content__prescription">
              <span>{{ item.finalPriceString }}</span>
            </td>
          </tr>
          <!-- <tr>
            <td colspan="4" class="text-right title__prescription">Total Price:</td>
            <td class="text-right content__prescription">
              <span>{{ payment.totalPriceString }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right sub title__prescription">Additional Price:</td>
            <td class="text-right sub content__prescription">
              <span>{{ payment.additionalPriceString }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right title__prescription">Promotion Price:</td>
            <td class="text-right content__prescription">
              <span>{{ payment.promotionPriceString }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right sub title__prescription">Adjustment Price:</td>
            <td class="text-right sub content__prescription">
              <span>{{ payment.adjustmentPriceString }}</span>
            </td>
          </tr> -->
          <tr>
            <td
              colspan="4"
              class="title__prescription"
              style="text-align: right"
            >
              <span>{{ isVnLanguage ? "Tổng cộng" : "Total price" }}</span>
            </td>
            <td class="text-center content__prescription">
              <span
                ><b>{{ payment.finalPriceString }}</b></span
              >
            </td>
          </tr>
          <!-- <tr>
            <td colspan="4" class="text-right title__prescription">Cash Advance:</td>
            <td class="text-right content__prescription">
              <span>
                {{ payment.customerCashAdvanceString }}
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-right title__prescription">Cash Return:</td>
            <td class="text-right content__prescription">
              <span>
                {{ payment.customerCashChangeString }}
              </span>
            </td>
          </tr> -->
        </tbody>
      </TableContent>
      <Footer class="mt-1">
        <slot name="footer">
          <span
            class="d-block w-100"
            style="text-align: right; padding-right: 10px; margin-bottom: 5px"
          >
            {{ isVnLanguage ? "Ngày, " : "Date, " }}
            <b class="bold__case">{{ payment.dateConfirmString }}</b>
          </span>

          <v-row justify="space-between">
            <v-col cols="4">
              <div class="footer__staffHandler">
                <span class="footer__staffHandler__date"></span>
                <span class="footer__staffHandler__role font-weight-bold">
                  {{ isVnLanguage ? "Bệnh nhân" : "Patient" }}
                </span>
                <div class="footer__staffHandler__signal"></div>
                <span class="footer__staffHandler__name font-weight-regular">{{
                  personalInfo.fullName
                }}</span>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="footer__staffHandler">
                <span class="footer__staffHandler__role font-weight-bold">
                  {{ isVnLanguage ? "Thu ngân" : "Casher" }}
                </span>
                <div class="footer__staffHandler__signal"></div>
                <span class="footer__staffHandler__name font-weight-regular">{{
                  receptionistName
                }}</span>
              </div>
            </v-col>
          </v-row>
        </slot>
      </Footer>
    </DetailPrescription>
  </Container>
  <!-- <div class="d-print-block justify-center" :class="{ 'd-none': hideRawData }">
    <p class="text-center text-uppercase sub-title mp-0">
      {{ clinic.brandDescription }}
    </p>
    <p class="text-center text-uppercase title mp-0">
      {{ clinic.brandName }}
    </p>
    <p class="text-center sub mp-0">
      Clinic: {{ clinic.clinicName }} - ĐC: {{ clinic.address }}
    </p>
    <p class="text-center sub mp-0">
      Web: {{ clinic.website }} - ĐT: {{ clinic.hotline }}
    </p>
    <p class="text-center sub mp-0 mt-2 mb-1">---oOo---</p>
    <p class="text-center text-uppercase title-2 mp-0">Payment Receipt</p>
    <v-row class="mp-0 mt-1">
      <v-col cols="2" class="mp-0 text-right">Fullname:</v-col>
      <v-col cols="10" class="mp-0 text-left">{{
        personalInfo.fullName
      }}</v-col>
    </v-row>
    <v-row class="mp-0 mt-1">
      <v-col cols="2" class="mp-0 text-right">Phone:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{ personalInfo.phone }}</v-col>
      <v-col cols="2" class="mp-0 text-right">Sex:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{
        personalInfo.genderType == 1 ? "Male" : "Female"
      }}</v-col>
    </v-row>
    <v-row class="mp-0 mt-1">
      <v-col cols="2" class="mp-0 text-right">Email:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{ personalInfo.email }}</v-col>
      <v-col cols="2" class="mp-0 text-right">Birthdate:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{
        formatDate(personalInfo.birthDate)
      }}</v-col>
    </v-row>
    <v-row class="mp-0 mt-1">
      <v-col cols="2" class="mp-0 text-right">Address:</v-col>
      <v-col cols="10" class="mp-0 text-left">{{ personalInfo.address }}</v-col>
    </v-row>
    <v-row class="mp-0 mt-1">
      <v-col cols="2" class="mp-0 text-right">Payment:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{
        paymentMethods[payment.paymentMethod - 1] || "Not paid yet."
      }}</v-col>
      <v-col cols="2" class="mp-0 text-right">Detail:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{
        payment.paymentMethodDescription || "N/A"
      }}</v-col>
      <v-col cols="2" class="mp-0 text-right">Note:</v-col>
      <v-col cols="10" class="mp-0 text-left">{{
        payment.note || "N/A"
      }}</v-col>
    </v-row>
    <table class="mt-4">
      <tbody>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center mw-120">Service Name</th>
          <th class="text-center">Qty</th>
          <th class="text-center">Price</th>
          <th class="text-center">Total</th>
        </tr>
        <tr v-for="item in payment.items" :key="item.order">
          <td class="text-center sub">{{ item.order }}</td>
          <td class="mw-120 sub">{{ item.itemName }}</td>
          <td class="text-center sub">{{ item.quantity }}</td>
          <td class="text-right sub">{{ item.priceString }}</td>
          <td class="text-right sub">{{ item.finalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Total Price:</td>
          <td class="text-right sub">{{ payment.totalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Additional Price:</td>
          <td class="text-right sub">{{ payment.additionalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Promotion Price:</td>
          <td class="text-right sub">{{ payment.promotionPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Adjustment Price:</td>
          <td class="text-right sub">{{ payment.adjustmentPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Final Price:</td>
          <td class="text-right sub">{{ payment.finalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Cash Advance:</td>
          <td class="text-right sub">
            {{ payment.customerCashAdvanceString }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Cash Return:</td>
          <td class="text-right sub">
            {{ payment.customerCashChangeString }}
          </td>
        </tr>
      </tbody>
    </table>
    <v-row class="mp-0 mt-2">
      <v-col cols="6" class="mp-0 text-center"></v-col>
      <v-col cols="6" class="mp-0 text-center sub"
        ><i>{{ payment.dateConfirmString }}</i></v-col
      >
    </v-row>
    <v-row class="mp-0 mt-2">
      <v-col cols="6" class="mp-0 text-center">
        <p><b>Patient</b></p>
        <p class="mt-15">{{ personalInfo.fullName }}</p>
      </v-col>
      <v-col cols="6" class="mp-0 text-center">
        <p><b>Receptionist</b></p>
        <p class="mt-15">{{ receptionistName }}</p>
      </v-col>
    </v-row>
  </div> -->
</template>

<script>
import moment from "moment";
import PaymentService from "../../../services/payment";
import PatientService from "../../../services/patient";
import BrandService from "../../../services/brand";
import { awaitAll, convertPriceString } from "../../../plugins/helper";
import { PaymentMethodType } from "@/plugins/constant";
import {
  Container,
  TitlePrescription,
  ClinicDetail,
  PatientInfo,
  ImageLogoBox,
  DetailPrescription,
  TableContent,
  Footer,
  // FooterPrescription
} from "./css/style";
export default {
  components: {
    Container,
    TitlePrescription,
    ClinicDetail,
    PatientInfo,
    ImageLogoBox,
    DetailPrescription,
    TableContent,
    Footer,
  },
  props: {
    hideRawData: {
      type: Boolean,
      default: true,
    },
    paymentID: {
      type: Number,
      default: null,
    },
    patientID: {
      type: Number,
      default: null,
    },
    clinicID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      language: "vn",
      paymentMethods: Object.keys(PaymentMethodType),
      payment: {
        items: [],
      },
      personalInfo: {},
      clinic: {},
      receptionistName: this.$store.getters.userData.fullName,
      isVnLanguage: this.$i18n.locale == "vn",
      genderOptions:
        this.$i18n.locale == "vn" ? ["Nam", "Nữ"] : ["Male", "Female"],
    };
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    async print(lang = null) {
      this.language = lang == null ? "vn" : lang;
      await awaitAll([
        this.renderPaymentDetail(),
        this.renderPatientDetail(),
        this.renderClinicDetail(),
      ]);
      window.print();
    },
    async renderClinicDetail() {
      var result = await BrandService.getBrandClinicByID(this.clinicID);
      if (result.error) {
        this.showError("Cannot get clinic info. Please try again later!");
        return;
      }
      this.clinic = result;
    },
    async renderPatientDetail() {
      var result = await PatientService.getPatientDetail(this.patientID);
      if (result.error) {
        this.showError("Cannot get patient info. Please try again later!");
        return;
      }
      const { personalInfo } = result;
      this.personalInfo = personalInfo;
      this.personalInfo.genderTypeName = this.genderOptions[
        this.personalInfo.genderType - 1
      ];
    },
    async renderPaymentDetail() {
      var result = await PaymentService.getPayment(this.paymentID);
      if (result.error) {
        this.showError("Cannot get payment summary. Please try again later!");
        return;
      }
      this.payment = result;
      this.payment.dateConfirmString = (this.payment.dateConfirmed
        ? moment(this.payment.dateConfirmed)
        : moment()
      ).format("DD/MM/YYYY");
      this.payment.additionalPriceString = convertPriceString(
        result.additionalPrice,
        false
      );
      this.payment.totalPriceString = convertPriceString(
        result.originalPrice,
        false
      );
      this.payment.promotionPriceString = convertPriceString(
        result.promotionPrice,
        false,
        true
      );
      this.payment.adjustmentPriceString = convertPriceString(
        result.adjustmentPrice,
        false
      );
      this.payment.finalPriceString = convertPriceString(
        result.finalPrice,
        false
      );
      this.payment.customerCashChangeString = convertPriceString(
        result.customerCashChange,
        false
      );
      this.payment.customerCashAdvanceString = convertPriceString(
        result.customerCashAdvance,
        false
      );
      for (var i = 0; i < this.payment.items.length; i++) {
        var item = this.payment.items[i];
        delete item.childrenItems;
        item.order = i + 1;
        item.quantity = 1;
        item.priceString = convertPriceString(item.currentPrice, false);
        item.finalPriceString = item.isFree ? "FREE" : item.priceString;
      }
      if (this.payment.staffConfirmFullName) {
        this.receptionistName = this.payment.staffConfirmFullName;
      } else {
        this.receptionistName = this.$store.getters.userData.fullName;
      }
    },
    tempSetLanguage(language) {
      // will replace for the next version when i18 is already
      this.language = language;
    },
  },
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}
table > tbody > tr > th {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  padding: 4px;
  text-align: left;
  background-color: white;
  color: black;
}
table > tbody > tr > th:first-child {
  border-left: 1px solid black;
}
table > tbody > tr > th:last-child {
  border-right: 1px solid black;
}
table > tbody > tr > td {
  border-bottom: 1px solid;
  border-left: 1px solid;
  padding: 2px;
}
table > tbody > tr:nth-last-child(2) > td {
  border-bottom: none;
}
table > tbody > tr:nth-last-child(5) > td {
  border-bottom: none;
}
table > tbody > tr:nth-last-child(6) > td {
  border-bottom: none;
}
table > tbody > tr > td:last-child {
  border-right: 1px solid;
}
@media print {
  * {
    margin: 0px;
    padding: 0px;

    font-family: Arial, Helvetica, sans-serif !important;
  }
  .title {
    font-size: 24px !important;
  }
  .title-2 {
    font-size: 18px !important;
    font-weight: bold;
  }
  .sub {
    font-size: 10px;
  }
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }
  .text-right {
    padding-right: 10px;
  }
  .text-left {
    width: 100%;
    display: inline-block;
    border-bottom: 1px dotted #bdbdbd;
    padding-bottom: 2px;
  }
  .mw-120 {
    min-width: 120px;
    max-width: 160px;
  }
}
</style>