import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

export default {
  searchPayments: async (keySearch = "", clinicID = "", statusID = "", page = 1, limit = 10) => {
    var query = {
      keySearch,
      clinicID,
      statusID,
      timeStart: "",
      timeEnd: "",
    }
    var headers = {
      page,
      limit,
    }
    return httpClient.get(`/PaymentSummary/Search`, query, headers);
  },
  searchHistoryPayments: async (patientID, page = 1, limit = 10) => {
    return httpClient.get(`/PaymentSummary/SearchHistory/${patientID}`, {}, { page, limit });
  },
  getPayment(paymentSummaryID) {
    var query = {
      paymentSummaryID
    }
    return httpClient.get(`/PaymentSummary/Get/{paymentSummaryID}`, query);
  },
  confirmPayment(paymentSummaryID, paymentConfirm = {}) {
    var query = {
      paymentSummaryID
    }
    return httpClient.put(`/PaymentSummary/Confirm/{paymentSummaryID}`, query, paymentConfirm);
  },
  deletePayment(paymentSummaryID) {
    var query = {
      paymentSummaryID
    }
    return httpClient.put(`/PaymentSummary/Delete/{paymentSummaryID}`, query);
  }
}